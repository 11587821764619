// Font-family
$font-family-sans-serif:    "Open Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base:            0.9rem !default;
$letter-spacing-base: 	    0.05rem;

// Colors
$white:                     #eeeeee;

// Typography
$body-bg:      			    #262b37 !default;
$body-color:      			$white;

$nav-link-color:            $white;
$nav-tabs-border-radius:    0;

$h1-font-size:              $font-size-base * 1.25 !default;
$h2-font-size:              $font-size-base * 1.15 !default;
$h3-font-size:              $font-size-base * 1.15 !default;
$h4-font-size:              $font-size-base !default;
$h5-font-size:              $font-size-base !default;

$card-border-color:	        #337ab7;  // border
$card-bg: 				    none;       // background
$card-cap-color: 			$white;     // header
$card-color: 				$white;     // text
$card-title-color: 			$white;     // h* text

$progress-height:			35px;
$progress-font-size:		0.85rem;
$progress-bg: 				none;
$progress-border-radius: 	0;
$progress-bar-color: 		$white;
$progress-bar-bg: 			#1c1f28;